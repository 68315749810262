export default {
  methods: {
    getProductType(internalProductTypeId) {
      switch (internalProductTypeId) {
        case 1: // AMCBankable
        case 6: // AmcNonBankable
        case 7: // AmcHybrid
        case 8: // AmcDigital
          return "AMC";
        case 2: // CLNUnsecured
        case 11: // CLNSecured
        case 12: // CLNConvertible
          return "CLN";
        case 3: // TrackerCertificateBankable
        case 9: // TrackerCertificateNonBankable
        case 10: // TrackerCertificateDigital
          return "TRC";
        default:
          return "Other";
      }
    },
    getProductTypeColor(internalProductTypeId) {
      switch (internalProductTypeId) {
        case 1: // AMCBankable
        case 6: // AmcNonBankable
        case 7: // AmcHybrid
        case 8: // AmcDigital
          return "var(--amc-color)";
        case 2: // CLNUnsecured
        case 11: // CLNSecured
        case 12: // CLNConvertible
          return "var(--cln-color)";
        case 3: // TrackerCertificateBankable
        case 9: // TrackerCertificateNonBankable
        case 10: // TrackerCertificateDigital
          return "var(--trc-color)";
        default:
          return "var(--element-bg)";
      }
    },
  },
};
